<script setup lang="ts">
// Events
const emit = defineEmits(["update:open"]);

// Props
const props = withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  }
);

// Functions
function close() {
  emit("update:open", false);
}
</script>

<template>
  <div
    v-if="open"
    @click.self="close"
    class="z-[86] fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity grid place-items-center p-4"
  >
    <section
      class="max-w-lg inline-block w-full max-h-full transform overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl sm:my-8 sm:p-6 sm:align-middle"
      :class="$attrs.class"
    >
      <slot />
    </section>
  </div>
</template>

<style scoped>

</style>
